window.addEventListener('DOMContentLoaded', (event) => {
  if(document.location.pathname == '/menu/pizza/romana') {
    document.getElementById('teglie-tooltip').addEventListener('click', function(e) {
      var rect     = this.getBoundingClientRect();
      var offset   = rect.top + document.documentElement.scrollTop;
      var lightbox = document.getElementById('lightbox-teglie')

      lightbox.style['top'] = (offset - 30).toString() + "px";
      lightbox.classList.remove('hidden');
    })

    document.getElementById('teglie-close').addEventListener('click', function(e) {
      document.getElementById('lightbox-teglie').classList.add('hidden');
    })
  }
})
